import ApiService from "@/core/services/api.service";

export const GET_CLIENTS = "getClients";
export const CREATE_CLIENT = "createClient";
export const UPDATE_CLIENT = "updateSymbol";
export const DELETE_CLIENT = "deleteClient";
export const SET_ERROR = "setError";

const state = {
  errors: null,
};

const actions = {
  [GET_CLIENTS](context, payloads) {
    return new Promise(resolve => {
      ApiService.get("clients")
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [DELETE_CLIENT](context, pk) {
    return new Promise(resolve => {
      ApiService.delete("clients/"+pk+'/')
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [CREATE_CLIENT](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("clients/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [UPDATE_CLIENT](context, payloads) {
    return new Promise(resolve => {
      ApiService.put("clients/"+payloads.pk+'/', payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
};
