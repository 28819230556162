import ApiService from "@/core/services/api.service";

export const GET_PROJECTS = "getProjects";
export const GET_PROJECT_TAGS = "getProjectTags";
export const GET_PROJECT_OWNERS = "getProjectOwners";
export const CREATE_PROJECT = "createProject";
export const UPDATE_PROJECT = "updateProject";
export const GET_TEAMWORK_PROJECTS = "getTeamworkProjects";
export const GET_TEAMWORK_PROJECT = "getTeamworkProject";
export const GET_WEATHER_DATA = "getWeatherData";
export const GET_STORM_DATA = "getStormData";
export const GET_BUILDFAX_DATA = "getBuildFaxData";
export const GET_GENERATE_DOC_DATA = "getGenerateDocData";
export const GET_ADDRESS_SUGGESTIONS = "getAddressSuggestions";
export const GENERATE_DOC = "generateDoc";
export const GET_INFORMATION_FROM_ADDRESS = "getInformationFromAddress";
export const UPDATE_FAVORITE = "updateFavorite";
export const DELETE_PROJECT = "deleteProject";
export const SET_ERROR = "setError";

const state = {
  errors: null,
};

const actions = {
  [GET_PROJECTS](context, payloads) {
    return new Promise(resolve => {
      ApiService.get("projects", payloads)

        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [GET_PROJECT_TAGS](context, payloads) {
    return new Promise(resolve => {
      ApiService.get("projects/get_project_tags", null)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [GET_PROJECT_OWNERS](context, payloads) {
    return new Promise(resolve => {
      ApiService.get("projects/get_project_owners", null)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [DELETE_PROJECT](context, pk) {
    return new Promise(resolve => {
      ApiService.delete("projects/"+pk+'/')
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [CREATE_PROJECT](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("projects/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [GET_TEAMWORK_PROJECTS](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("projects/teamwork_projects/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [GET_TEAMWORK_PROJECT](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("projects/teamwork_project/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [UPDATE_FAVORITE](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("projects/change_favorite/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [GET_WEATHER_DATA](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("projects/get_weather_data/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [GET_STORM_DATA](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("projects/get_storm_data/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [GENERATE_DOC](context, payloads) {
    return new Promise(resolve => {
      ApiService.postDoc("projects/generate_doc/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [GET_BUILDFAX_DATA](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("projects/get_buildfax_data/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

    [GET_GENERATE_DOC_DATA](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("projects/get_generate_doc_data/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },


  [GET_ADDRESS_SUGGESTIONS](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("projects/get_address_suggestions/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

   [GET_INFORMATION_FROM_ADDRESS](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("projects/get_information_from_address/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [UPDATE_PROJECT](context, payloads) {
    return new Promise(resolve => {
       const formData = new FormData();
      ApiService.put("projects/"+payloads.pk+'/', payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

};

const getters = {

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
