// action types
import ApiService from "@/core/services/api.service";

export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const GET_MEMBERS = "getMembers";
export const UPDATE_PROFILE = "updateProfile";
export const UPDATE_PASSWORD = "updatePassword";
export const GET_INVOICES = "getInvoices";
export const GET_OFFICES = "getOffices";

export const CREATE_USER = "createUser";
export const UPDATE_USER = "updateUser";
export const GET_USERS = "getUsers";
export const DELETE_USER = "deleteUsers";

export const SET_ERROR = "setError";

const state = {
  errors: null,
  user_account_info: {
    first_name: "",
    last_name: "",
    admin: false,
    use_own: false,
    email: ""
  },
};

const getters = {
  currentUserAccountInfo(state) {
    return state.user_account_info;
  },
};

const actions = {
  [UPDATE_ACCOUNT_INFO](context) {
    return new Promise(resolve => {
      ApiService.get("users/get_user_info")
        .then(({ data }) => {
          context.commit(SET_ACCOUNT_INFO, data);
          resolve(data)
        })
        .catch(({ response }) => {
        });
    });
  },

  [UPDATE_PROFILE](context, payload) {
    return new Promise(resolve => {
      ApiService.post("users/update_profile/", payload)
        .then(({ data }) => {
          context.commit(SET_ACCOUNT_INFO, data);
          resolve(data)
        })
        .catch(({ response }) => {
        });
    });
  },

   [UPDATE_PASSWORD](context, payload) {
    return new Promise(resolve => {
      ApiService.post("users/update_password/", payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
        });
    });
  },

  [GET_MEMBERS](context, office_id) {
    return new Promise(resolve => {
      ApiService.post("users/get_members/", {'office': office_id})
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
        });
    });
  },

  [GET_INVOICES](context, payload) {
    return new Promise(resolve => {
      ApiService.post("users/get_invoices/", payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
        });
    });
  },


  [GET_OFFICES](context) {
    return new Promise(resolve => {
      ApiService.get("users/get_offices")
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data)
        })
        .catch(({ response }) => {
        });
    });
  },
  [GET_USERS](context, payloads) {
    return new Promise(resolve => {
      ApiService.get("users")
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [CREATE_USER](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("users/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [UPDATE_USER](context, payloads) {
    return new Promise(resolve => {
      ApiService.put("users/"+payloads.pk+'/', payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
    [DELETE_USER](context, pk) {
    return new Promise(resolve => {
      ApiService.delete("users/"+pk+'/')
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
