import ApiService from "@/core/services/api.service";

export const GET_SETTINGS = "getSettings";
export const CREATE_SETTING = "createSetting";
export const UPDATE_SETTING = "updateSetting";
export const DELETE_SETTING = "deleteSetting";
export const API_TESTING = "apiTesting";
export const CREATE_MULTIPLE = "createMultiple";
export const SET_ERROR = "setError";

const state = {
  errors: null,
};

const actions = {
  [GET_SETTINGS](context, payloads) {
    return new Promise(resolve => {
      ApiService.get("settings")
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [DELETE_SETTING](context, pk) {
    return new Promise(resolve => {
      ApiService.delete("settings/"+pk+'/')
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [CREATE_SETTING](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("settings/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [CREATE_MULTIPLE](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("settings/create_multiple/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [API_TESTING](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("settings/api_test/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [UPDATE_SETTING](context, payloads) {
    return new Promise(resolve => {
       const formData = new FormData();
      ApiService.put("settings/"+payloads.pk+'/', payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

};

const getters = {

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
