import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
   mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/projects",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/projects",
          name: "Projects",
          component: () => import("@/view/pages/Projects.vue")
        },
        {
          path: "/users",
          name: "Users",
          component: () => import("@/view/pages/Users.vue")
        },
         {
          path: "/clients",
          name: "Clients",
          component: () => import("@/view/pages/Clients.vue")
        },
         {
          path: "/settings",
          name: "settings",
          component: () => import("@/view/pages/Settings.vue")
        },
         {
          path: "/projects/detail/:id",
          name: "project-detail",
          component: () => import("@/view/pages/ProjectDetail.vue")
        },
      ]
    },
      {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },

  ]
});
